import request from '@/utils/request'

/** =============展会管理============== */

// 获取展会上下架列表(附带查询)
export function exShelfAndUnshelfList(data) {
  return request({
    url: '/user/admin/hold/exhibition/exShelfList',
    method: 'POST',
    data,
  })
}
// 展会上下架
export function exIsShelfOrUnshelf(data) {
  return request({
    url: '/user/admin/hold/exhibition/exPutShelf',
    method: 'POST',
    data,
  })
}

export function meetisShelfOrUpdate(data) {
  return request({
    url: '/user/admin/hold/meeting/update',
    method: 'POST',
    data,
  })
}

/** =============门票列表============== */

// 查询用户门票列表
export function useTicketList(data) {
  return request({
    url: '/user/admin/ticket/user/ticketList',
    method: 'POST',
    data,
  })
}
// 查询票务门票列表
export function ticketList(data) {
  return request({
    url: '/user/admin/ticket/list',
    method: 'POST',
    data,
  })
}
// 编辑票种备注
export function updateRemark(data) {
  return request({
    url: '/user/admin/ticket/updateRemark',
    method: 'POST',
    data,
  })
}

/** =============门票投放============== */

// 创建门票投放群组
export function createTicketsThrow(data) {
  return request({
    url: '/user/admin/ticket/put/createOrUpdate',
    method: 'POST',
    data,
  })
}
// 查看门票投放列表
export function checkTricksThrowList(data) {
  return request({
    url: '/user/admin/ticket/put/list',
    method: 'POST',
    data,
  })
}
// 查看门票投放详情列表
export function checkTricksThrowDetailList(data) {
  return request({
    url: '/user/admin/ticket/put/detailList',
    method: 'POST',
    data,
  })
}
// 投放门票
export function importTicket(data, batchNo) {
  return request({
    url: `/user/admin/ticket/put/importTicket?batchNo=${batchNo}`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data,
  })
}
// 下载门票投放明细列表
export function doloadTicket(batchNo) {
  return request({
    url: `/user/admin/ticket/put/exportList/${batchNo}`,
    method: 'GET',
    // 没这句多半会提示文件损坏
    responseType: 'blob',
  })
}

/** =============订单============== */

// 订单列表
export function orderList(data) {
  return request({
    url: `/user/admin/order/list`,
    method: 'POST',
    data,
  })
}
// 订单详情
export function orderDetail(orderNo) {
  return request({
    url: `/user/admin/order/detail/${orderNo}`,
    method: 'POST',
  })
}

// 订单删除
export function deleteOrderDetail(orderNo) {
  return request({
    url: `/user/admin/order/del/${orderNo}`,
    method: 'DELETE',
  })
}

// 更改门票状态
export function changeTicketsStatus(orderNo, ticketCode) {
  return request({
    url: `/user/admin/order/updateUserTicketStatus/${orderNo}/${ticketCode}`,
    method: 'POST',
  })
}
// 退款审核
export function reqRefundAudit(orderNo) {
  return request({
    url: `/user/admin/order/refundAudit/${orderNo}
    `,
    method: 'POST',
  })
}
// 添加门票优惠券
export function reqAddDiscountCode(data) {
  return request({
    url: `/user/admin/ticket/addDiscountCode`,
    method: 'POST',
    data,
  })
}

// 修改门票优惠码剩余数量
export function updateDiscountCodeUseNum(id, defineUseNum) {
  return request({
    url: `/user/admin/ticket/updateDiscountCodeUseNum/${id}/${defineUseNum}`,
    method: 'POST',
  })
}

// 查询门票优惠码列表
export function reqDiscountList(data) {
  return request({
    url: `/user/admin/ticket/discountList`,
    method: 'POST',
    data,
  })
}
// 查询门票优惠码更改状态
export function reqUpdateDiscountCodeStatus(id, status) {
  return request({
    url: `/user/admin/ticket/updateDiscountCodeStatus/${id}/${status}`,
    method: 'POST',
  })
}
// 下载门票优惠码接口
export function reqDownloadDiscountCode(ticketCode) {
  return request({
    url: `/user/admin/ticket/exportDiscountCode/${ticketCode}`,
    method: 'GET',
    // 没这句多半会提示文件损坏
    responseType: 'blob',
  })
}
// 获取门票分享码&链接
export function shareTicketsCode(ticketCode) {
  return request({
    url: `/user/admin/ticket/shareCode/${ticketCode}`,
    method: 'GET',
    // 没这句多半会提示文件损坏
  })
}
// 门票集合页面

// 获取门票集合页
export function getTicketCategory(meetingCode) {
  return request({
    url: `/user/admin/ticket/getTicketCategory/${meetingCode}`,
    method: 'GET',
  })
}
// 新增门票集合页配置
export function addTicketCategory(data) {
  return request({
    url: `/user/admin/ticket/addTicketCategory`,
    method: 'POST',
    data,
  })
}
// 编辑门票集合页配置
export function updateTicketCategory(data) {
  return request({
    url: `/user/admin/ticket/updateTicketCategory`,
    method: 'POST',
    data,
  })
}
// 新增门票集合页里面的配置
export function addTicketCategoryRef(data) {
  return request({
    url: `/user/admin/ticket/addTicketCategoryRef`,
    method: 'POST',
    data,
  })
}

// 删除门票集合页配置
export function delTicketCategory(data) {
  return request({
    url: `/user/admin/ticket/delTicketCategory`,
    method: 'POST',
    data,
  })
}
// 获取小程下配置的所有门票列表带分页
export function getTicketList(params) {
  return request({
    url: `/user/admin/ticket/getTicketList`,
    method: 'GET',
    params,
  })
}
// 编辑门票集合页里面的配置
export function updateTicketCategoryRef(data) {
  return request({
    url: `/user/admin/ticket/updateTicketCategoryRef`,
    method: 'POST',
    data,
  })
}

// 删除门票集合页里面的配置
export function delTicketCategoryRef(data) {
  return request({
    url: `/user/admin/ticket/delTicketCategoryRef`,
    method: 'POST',
    data,
  })
}

//  展会渠道列表

export function exhibitionChannelList(data) {
  return request({
    url: `/user/admin/channel/page`,
    method: 'POST',
    data,
  })
}

export function addExhibitionChannel(data) {
  return request({
    url: `/user/admin/channel/save`,
    method: 'POST',
    data,
  })
}

// 统计报表接口

// 展会订单报表
export function exportExOrderReport(meetingCode) {
  return request({
    url: `/user/admin/hold/exhibition/exportExOrderReport/${meetingCode}`,
    method: 'GET',
  })
}
// 媒体注册报表
export function exportMediaRegisterReport(meetingCode) {
  return request({
    url: `/user/admin/hold/exhibition/exportRegisterReport/AUDIENCE/EXHIBITION/${meetingCode}`,
    method: 'GET',
  })
}

// 观众注册报表
export function exportVisitorRegisterReport(meetingCode) {
  return request({
    url: `/user/admin/hold/exhibition/exportRegisterReport/AUDIENCE/HOLD_MEETING/${meetingCode}`,
    method: 'GET',
  })
}

// 展会门票订单报表
export function exportExTicketReport(meetingCode) {
  return request({
    url: `/user/admin/hold/exhibition/exportExTicketReport/${meetingCode}`,
    method: 'GET',
  })
}

// 观众签到数据
export function exportExSignUpReport(meetingCode) {
  return request({
    url: `/user/admin/hold/exhibition/exportExSignUpReport/${meetingCode}`,
    method: 'GET',
  })
}

export function exDataDetail(meetingCode) {
  return request({
    url: `/user/admin/hold/exhibition/exDataDetail?meetingCode=${meetingCode}`,
    method: 'POST',
  })
}
export function signUpByInside(data) {
  return request({
    url: `/user/admin/ticket/inside/signUpByInside`,
    method: 'POST',
    data,
  })
}

/**
 * @description: 查询关联主办方信息列表
 * @return {*}
 */
export function getHasRelatedOrgList(params) {
  return request({
    url: `/user/admin/ex/forum/meetingHostList`,
    method: 'GET',
    params,
  })
}

/**
 * @description: 根据手机号查询主办方信息
 * @return {*}
 */
export function getMeetingHostByPhone(params) {
  return request({
    url: `/user/admin/ex/forum/getMeetingHost`,
    method: 'GET',
    params,
  })
}

/**
 * @description: 展会关联主办方信息
 * @return {*}
 */
export function relatedOrgInfo(uid, meetingCode) {
  return request({
    url: `/user/admin/ex/forum/addMeetingHost/${uid}/${meetingCode}`,
    method: 'POST',
  })
}

/**
 * @description: 取消关联主办方
 * @return {*}
 */
export function cancelRelated(uid, meetingCode) {
  return request({
    url: `/user/admin/ex/forum/cancelMeetingHost/${uid}/${meetingCode}`,
    method: 'POST',
  })
}

/**
 * @description: 获取当前主办下会议列表
 * @return {*}
 */
export function getMeetListByHost(params) {
  return request({
    url: `/user/admin/ex/forum/host/meetingList`,
    method: 'GET',
    params,
  })
}

/**
 * @description: 关联主办方下的会议
 * @return {*}
 */

export function doRelatedMeet(data) {
  return request({
    url: `/user/admin/ex/forum/relatedMeeting`,
    method: 'POST',
    data,
  })
}

/**
 * @description: 取消关联主办方下的会议
 * @return {*}
 */
export function cancelRelatedMeet(data) {
  return request({
    url: `/user/admin/ex/forum/cancelExForum`,
    method: 'POST',
    data,
  })
}

/**
 * @description: 查询已关联的会议信息
 * @return {*}
 */
export function getRelatedMeetDetail(params) {
  return request({
    url: `/user/admin/ex/forum/get`,
    method: 'GET',
    params,
  })
}

/**
 * @description: 更新关联的论坛会议信息
 * @return {*}
 */
export function updateRelatedMeetDetail(data) {
  return request({
    url: `/user/admin/ex/forum/updateForum`,
    method: 'POST',
    data,
  })
}

/**
 * @description: 查询已关联的论坛-外层全部
 * @return {*}
 */
export function getAllHasRelatedMeet(params) {
  return request({
    url: `/user/admin/ex/forum/meetingList`,
    method: 'GET',
    params,
  })
}
/**
 * @description: 关联活动反显
 * @return {*}关联
 */
export function getQueryList(params) {
  return request({
    url: `/user/admin/activity/queryList`,
    method: 'GET',
    params,
  })
}
/**
 * @description: 关联活动保存
 * @return {*}
 */
export function saveOrUpdate(data) {
  return request({
    url: `/user/admin/activity/saveOrUpdate`,
    method: 'POST',
    data,
  })
}

/**
 * @description: 推送数据至闸机
 * @return {*}
 */
export function pushDataToGate(data) {
  return request({
    url: `/user/zdr/push/batchPushGate`,
    method: 'POST',
    data,
  })
}

/**
 * @description: 获取小程序二维码
 * @return {*}
 */
export function getQrcodeUrls(relationId, type) {
  return request({
    url: `/user/admin/qr/getQrCodeUrls/${relationId}/${type}`,
    method: 'get',
  })
}

/**
 * @description: 查询展会协同子账号列表
 * @param {*} params
 * @return {*}
 */
export function getSynergyAccount(params) {
  return request({
    url: `/user/admin/exhibit/account/querySynergyAccount`,
    method: 'get',
    params,
  })
}
/**
 * @description: 添加展会协同子账号
 * @param {*} params
 * @return {*}
 */
export function addSynergyAccount(data) {
  return request({
    url: `/user/admin/exhibit/account/addSynergyAccount`,
    method: 'POST',
    data,
  })
}

/**
 * @description: 删除展会协同子账号
 * @param {*} data
 * @return {*}
 */
export function deleteSynergyAccount(data) {
  return request({
    url: `/user/admin/exhibit/account/delSynergyAccount?iamUid=${data.iamUid}&meetingCode=${data.meetingCode}`,
    method: 'POST',
  })
}

export function getAllHoldMeetingManageList(data) {
  return request({
    url: `/user/admin/hold/meeting/queryList`,
    method: 'POST',
    data,
  })
}

// 商机配对报表导出
export function exportMeetingInvitedReport(meetingCode) {
  return request({
    url: `/user/admin/hold/exhibition/exportMeetingInvitedReport/${meetingCode}`,
    method: 'GET',
  })
}

// 查询报名身份列表
export function getRegisterConfigList(meetingCode) {
  return request({
    url: `/user/meeting/getRegisterConfigList/${meetingCode}`,
    method: 'GET',
  })
}
// 注册报表导出
export function dataExportB(data) {
  return request({
    url: `/user/event/data/exportB`,
    method: 'POST',
    data,
  })
}

// 注册报表
export function dataGetB(data) {
  return request({
    url: `/user/event/data/getB`,
    method: 'POST',
    data,
  })
}

// 查询优惠吗使用
export function queryDiscountCode(params, ticketCode) {
  return request({
    url: `/user/admin/ticket/queryDiscountCode/${ticketCode}`,
    method: 'GET',
    params,
  })
}

// 启用/停用优惠码
export function updateDiscountCodeEnableStatus(id, enableStatus) {
  return request({
    url: `/user/admin/ticket/updateDiscountCodeEnableStatus/${id}/${enableStatus}`,
    method: 'POST',
  })
}

export function attendAuditList(data) {
  return request({
    url: `/user/admin/attend/auditList`,
    method: 'POST',
    data,
  })
}
export function attendAuditDetail(meetingCode, audienceUid, identity) {
  return request({
    url: `/user/admin/attend/auditDetail/${meetingCode}/${audienceUid}/${identity}`,
    method: 'GET',
  })
}
export function attendAudit(data) {
  return request({
    url: `/user/admin/attend/audit`,
    method: 'POST',
    data,
  })
}

/**
 * @description: 新增投放数据
 * @param {*} data
 * @return {*}
 */
export function addEnterpriseMarketingAddDelivery(data) {
  return request({
    url: `/user/admin/enterprise/marketing/addDelivery`,
    method: 'POST',
    data,
  })
}

/**
 * @description: 查询投放数据列表
 * @param {*} params
 * @return {*}
 */
export function getEnterpriseMarketingQueryDelivery(params) {
  return request({
    url: `/user/admin/enterprise/marketing/queryDelivery`,
    method: 'GET',
    params,
  })
}

export function getEnterpriseMarketingImportUser(data) {
  return request({
    url: `/user/admin/enterprise/marketing/importUser`,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function getEnterpriseMarketingQueryMessageTemplate(params) {
  return request({
    url: `/user/admin/enterprise/marketing/queryMessageTemplate`,
    method: 'GET',
    params,
  })
}

/**
 * @description: 短信投放
 * @param {*} data
 * @return {*}
 */
export function getEnterpriseMarketingSendMessage(data) {
  return request({
    url: `/user/admin/enterprise/marketing/message/send`,
    method: 'POST',
    data,
  })
}

export function editEnterpriseMarketingUpdateDelivery(params) {
  return request({
    url: `/user/admin/enterprise/marketing/updateDelivery`,
    method: 'GET',
    params,
  })
}

/**
 * @description: 查询主办和协同账号管理权限
 * @param {*} data
 * @return {*}
 */
export function exhibitionQueryPermissions(params) {
  return request({
    url: `/user/admin/hold/exhibition/queryPermissions`,
    method: 'GET',
    params,
  })
}

/**
 * @description: 投放历史记录
 * @param {*} params
 * @return {*}
 */
export function getEnterpriseMarketingMessageHistory(params) {
  return request({
    url: `/user/admin/enterprise/marketing/message/history`,
    method: 'GET',
    params,
  })
}

export function enterpriseMarketingDelDelivery(id) {
  return request({
    url: `/user/admin/enterprise/marketing/delDelivery/${id}`,
    method: 'POST',
  })
}

/**
 * @description: 手机号短信投放
 * @return {*}
 */

export function getEnterpriseMarketingPhoneMessageSend(data) {
  return request({
    url: `/user/admin/enterprise/marketing/phone/message/send`,
    method: 'POST',
    data,
  })
}

/**
 * @description: 查询用户人数
 * @param {*} params
 * @return {*}
 */
export function getEnterpriseMarketingQueryUsernum(data) {
  return request({
    url: `/user/admin/enterprise/management/query/userNum`,
    method: 'POST',
    data,
  })
}

export function getEnterpriseMarketingQueryEnterpriseUserDetail(params) {
  return request({
    url: `/user/admin/enterprise/management/queryEnterpriseUserDetail`,
    method: 'GET',
    params,
  })
}

export function getEnterpriseMarketingMessageHistoryUpdateRemark(params) {
  return request({
    url: `/user/admin/enterprise/marketing/message/history/updateRemark`,
    method: 'GET',
    params,
  })
}

// 批量同步嘉宾信息
export function syncGuests(params) {
  return request({
    url: `/user/admin/hold/meeting/sync/guests`,
    method: 'GET',
    params,
  })
}

// 查询嘉宾列表
export function queryGuestList(params) {
  return request({
    url: `/user/admin/hold/meeting/queryGuestList`,
    method: 'GET',
    params,
  })
}

// 自动翻译
export function translationGuest(code) {
  return request({
    url: `/user/admin/hold/meeting/translationGuest/${code}`,
    method: 'POST',
  })
}

// 修改嘉宾翻译
export function updateTranslationGuest(data) {
  return request({
    url: `/user/admin/hold/meeting/updateTranslationGuest`,
    method: 'POST',
    data,
  })
}

// 查询嘉宾详情
export function queryGuestByCode(params) {
  return request({
    url: `/user/admin/hold/meeting/queryGuestByCode`,
    method: 'GET',
    params,
  })
}

export function updateGuestStatus(code, status) {
  return request({
    url: `/user/admin/hold/meeting/update/guestStatus/${code}/${status}`,
    method: 'POST',
  })
}

export function queryRelatedMeetingType(params) {
  return request({
    url: `/user/admin/ex/forum/queryRelatedMeetingType`,
    method: 'GET',
    params,
  })
}

export function appConfigGetlist(params) {
  return request({
    url: `/user/ai/app/config/getList`,
    method: 'GET',
    params,
  })
}
